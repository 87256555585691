:root {
    // themes color
    --color-primary: #DF0A0A;
    --color-primary-2: #0B4DF5;
    --color-primary-3: #F64A00;
    --color-primary-4: #5264F3;
    --color-primary-5: #B00F0F;
    --color-primary-6: #F64A00;
    --color-primary-alta: #FFECEC;
    
    --color-secondary:#111D5E;
    --color-terciary:#90FF03;

    --color-gray: #f6f6f6;
    --color-gray-2: #f5f8fa;
    --color-subtitle: #f9004d;

    // body color
    --color-body: #5D666F;


    // title color
    --color-heading-1: #1C2539;

    // background color
    --background-color-1:#181C25;


    --color-white:#fff;
    --color-light-heading: #181c32;
    --color-light-body: #65676b;
    --color-border-white: #00000024;

    // notify Colors
    --color-success: #3EB75E;
    --color-danger: #FF0003;
    --color-warning: #FF8F3C;
    --color-info: #1BA2DB;


    //Social icon colors
    --color-facebook: #3B5997;
    --color-twitter: #1BA1F2;
    --color-youtube: #ED4141;
    --color-linkedin: #0077B5;
    --color-pinterest: #E60022;
    --color-instagram: #C231A1;
    --color-vimeo: #00ADEF;
    --color-twitch: #6441A3;
    --color-discord: #7289da;


    // Font weight
    --p-light: 300;
    --p-regular: 400;
    --p-medium: 500;
    --p-semi-bold: 600;
    --p-bold: 700;
    --p-extra-bold: 800;
    --p-black: 900;

    // Font weight
    --s-light: 300;
    --s-regular: 400;
    --s-medium: 500;
    --s-semi-bold: 600;
    --s-bold: 700;
    --s-extra-bold: 800;
    --s-black: 900;


    //transition easing
    --transition: 0.3s;


    // Font Family
    --font-primary: 'DM Sans', sans-serif;
    --font-secondary: 'Red Hat Display', sans-serif;


    //Fonts Size
    --font-size-b1: 16px;
    --font-size-b2: 18px;
    --font-size-b3: 22px;


    //Line Height
    --line-height-b1: 26px;
    --line-height-b2: 30px;
    --line-height-b3: 1.7;


    // Heading Font
    --h1: 60px;
    --h2: 48px;
    --h3: 36px;
    --h4: 30px;
    --h5: 24px;
    --h6: 20px;

}

// Layouts Variation
$smlg-device: 'only screen and (max-width: 1199px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';