// header style start hear
.header-top-one {
    .left {
        display: flex;
        align-items: center;

        .mail {
            position: relative;

            a {
                padding: 11px 0;
                display: block;
                color: #fff;
                margin-right: 50px;
                font-size: 14px;
                position: relative;
                transition: var(--transition);

                i {
                    margin-right: 6px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 12px;
                    height: 1px;
                    width: 0%;
                    background: #fff;
                    transition: var(--transition);
                }

                &:hover {
                    color: var(--color-white);

                    &::after {
                        width: 100%;
                    }
                }
            }

            &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 14px;
                background: #F36464;
                right: 25px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .working-time {
            p {
                color: #fff;
                font-size: 14px;

                i {
                    margin-right: 6px;
                }
            }
        }
    }

    .right {
        display: flex;
        justify-content: flex-end;

        .top-nav {
            display: flex;
            list-style: none;
            padding-left: 0;
            margin: 0;

            li {
                margin: 0;
                margin-right: 20px;

                a {
                    font-size: 14px;
                    transition: var(--transition);
                    position: relative;
                    padding: 11px 0;
                    display: block;
                    color: #fff;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 12px;
                        height: 1px;
                        width: 0%;
                        background: #fff;
                        transition: var(--transition);
                    }

                    &:hover {
                        &::after {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .social-wrapper-one {
            margin-left: 30px;
            position: relative;

            a {
                i {
                    transition: .3s;
                }
            }

            a {
                &:hover {
                    i {
                        transform: scale(1.2);
                    }
                }
            }

            i {
                color: #fff;
            }

            &::after {
                position: absolute;
                content: '';
                width: 1px;
                height: 14px;
                left: -25px;
                top: 50%;
                transform: translateY(-50%);
                background: #F36464;
            }
        }
    }

    &.header-top-four {
        border-bottom: 1px solid #EDEDED;
        @media(max-width:1200px){
            display: none;
        }

        &.header-top-five{
            .top-left{
                a{
                    color: var(--color-primary-2);
                }
            }
            .right-h-three{
                .header-top-right{
                    .email{
                        i{
                            color: var(--color-primary-2);
                        }
                    }
                    .call{
                        i{
                            color: var(--color-primary-2);
                        }
                    }
                }
            }
        }

        .top-left {
            padding: 10px 0;
            padding-left: 0;
            font-size: 16px;
            font-weight: 400;
            color: #5D666F;
            display: flex;
            align-items: center;

            a {
                color: var(--color-primary-4);
                display: flex;
                align-items: center;
                margin-left: 5px;

                i {
                    transition: .3s;
                    margin-left: 5px;
                }

                &:hover {
                    i {
                        margin-left: 10px;
                    }
                }
            }
        }

        .right-h-three {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .header-top-right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-right: 0;

                .email {
                    margin-right: 30px;

                    i {
                        margin-right: 10px;
                        color: var(--color-primary-4);
                    }

                    a {
                        color: #1C2539;
                        transition: .3s;
                        font-weight: 500;

                        &:hover {
                            color: var(--color-primary-4);
                        }
                    }
                }

                .call {
                    margin-right: 0;

                    i {
                        margin-right: 10px;
                        color: var(--color-primary-4);
                        transform: rotate(-30deg);
                    }

                    a {
                        color: #1C2539;
                        transition: .3s;
                        font-weight: 500;

                        &:hover {
                            color: var(--color-primary-4);
                        }
                    }
                }
            }
        }
    }
}

.header-main-one {
    .thumbnail {
        a {
            padding: 36px 0;
            display: block;
            max-width: max-content;

            @media #{$small-mobile} {
                padding: 18px 0;
            }
        }
    }
}

.header-two {
    .main-header {
        .nav-main {
            ul {
                li {
                    @media #{$laptop-device} {
                        margin-right: 25px !important;
                    }

                    &.has-droupdown {
                        @media #{$laptop-device} {
                            margin-right: 37px !important;
                        }
                    }
                }
            }
        }
    }
}



.main-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .nav-main {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;

            li {
                margin: 0;
                margin-right: 36px;

                a {
                    padding: 48px 0;
                    display: block;
                    color: #1C2539;
                    font-size: 16px;
                    font-weight: 500;
                    position: relative;
                    transition: var(--transition);
                    min-width: max-content;

                    &::after {
                        position: absolute;
                        content: "";
                        left: 0;
                        bottom: 50px;
                        height: 2px;
                        width: 0%;
                        border-radius: 1px;
                        background: var(--color-primary);
                        transition: var(--transition);
                    }

                    &:hover {
                        color: var(--color-primary);

                        &::after {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .button-area {
        display: flex;
        align-items: center;
        margin-left: 80px;

        @media screen and (max-width: 1399px) {
            margin-left: 16px;
        }

        @media screen and (max-width: 1199px) {
            margin-left: 16px;
            margin: 33px 0;
        }

        @media #{$small-mobile} {
            margin: 10px 0;
        }

        button {
            &.menu {
                .menu-dark {
                    display: block;
                    transition: .3s;
                }

                .menu-light {
                    display: none;
                    transition: .3s;
                }

                &:hover {
                    .menu-dark {
                        display: none;
                    }

                    .menu-light {
                        display: block;
                    }
                }
            }
        }
    }

    &.main-header-four {
        .nav-main {
            ul {
                li {
                    a {
                        &:hover {
                            color: var(--color-primary-4);
                        }

                        &::after {
                            background: var(--color-primary-4);
                        }
                    }

                    .submenu {
                        border-color: var(--color-primary-4);
                        border-width: 4px 0 0 0;

                        &::after {
                            border-bottom-color: var(--color-primary-4);
                        }
                    }
                }
            }
        }
        &.main-header-five{
            .nav-main {
                ul {
                    li {
                        a {
                            &:hover {
                                color: var(--color-primary-2);
                            }
    
                            &::after {
                                background: var(--color-primary-2);
                            }
                        }
    
                        .submenu {
                            border-color: var(--color-primary-2);
                            border-width: 4px 0 0 0;
    
                            &::after {
                                border-bottom-color: var(--color-primary-2);
                            }
                        }
                    }
                }
            } 
            .button-area{
                .btn-primary-4{
                    background: var(--color-primary-2);
                    border-radius: 30px;
                    &:hover{
                        background: var(--color-gray);
                    }
                }
                .btn-primary-alta-four{
                    border-radius: 30px;
                    &:hover{
                        background: var(--color-primary-2) !important;
                    }
                }
            }
        }
    }
}
.six .main-header.main-header-four.main-header-five .nav-main ul li a:hover{
    color: var(--color-primary-6);
}
.six .main-header.main-header-four.main-header-five .nav-main ul li a::after{
    background: var(--color-primary-6);
}

.social-wrapper-one {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;

    li {
        margin: 0;

        a {
            color: #fff;
            margin-right: 20px;
            padding: 11px 0;

            i {
                color: #fff;
                transition: .3s;
                font-size: 14px;
            }

            &:hover {
                i {
                    color: var(--color-primary);
                }
            }
        }
    }
}

.header-three.header--sticky.sticky {
    backdrop-filter: none;
    box-shadow: none;
}

.header--sticky {
    transition: 0.3s;
    top: 0;
    z-index: 999;
    position: relative;
}

.header--sticky.sticky {
    position: fixed !important;
    top: 0;
    display: block;
    backdrop-filter: blur(9px);
    z-index: 999;
    width: 100%;
    box-shadow: 0px 7px 18px #1810100d;
}

// banner header three

.header-three {
    position: absolute;
    width: 100%;

    .main-header .nav-main ul li a {
        padding: 42px 0;
    }

    .main-header .nav-main ul li a::after {
        bottom: 46px;
    }

    .header-top-three {
        background: rgb(255, 255, 255);
        border-bottom: 1px solid #EDEDED;

        @media #{$smlg-device} {
            display: none;
        }

        .top-left {
            padding: 10px 0;
            padding-left: 50px;
            font-size: 16px;
            font-weight: 400;
            color: #5D666F;
            display: flex;
            align-items: center;

            a {
                color: var(--color-primary-3);
                display: flex;
                align-items: center;
                margin-left: 5px;

                i {
                    transition: .3s;
                    margin-left: 5px;
                }

                &:hover {
                    i {
                        margin-left: 10px;
                    }
                }
            }
        }

        .right-h-three {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .header-top-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 30px;

        .email {
            margin-right: 30px;

            i {
                margin-right: 10px;
                color: #F64A00;
            }

            a {
                color: #1C2539;
                transition: .3s;
                font-weight: 500;

                &:hover {
                    color: var(--color-primary-3);
                }
            }
        }

        .call {
            margin-right: 30px;

            i {
                margin-right: 10px;
                color: #F64A00;
                transform: rotate(-30deg);
            }

            a {
                color: #1C2539;
                transition: .3s;
                font-weight: 500;

                &:hover {
                    color: var(--color-primary-3);
                }
            }
        }
    }

    // main header
    .main-header-three {
        background: #fff;

        &.main-header {
            justify-content: flex-start;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                left: -14px;
                top: -48%;
                height: 148%;
                background: var(--color-primary-3);
                width: 15px;
                border-radius: 0 0 0 15px;
            }

            &::before {
                content: "";
                position: absolute;
                right: -14px;
                top: -48%;
                height: 148%;
                background: var(--color-primary-3);
                width: 15px;
                border-radius: 0 0 15px 0;
            }

            .nav-main ul li a:hover {
                color: var(--color-primary-3);
            }

            .nav-main ul li a::after {
                background: var(--color-primary-3);
            }

            .mainmenu li.has-droupdown .submenu {
                border-top-color: var(--color-primary-3);
            }
        }

        &.main-header-four {
            &::after {
                display: none;
            }

            &::before {
                display: none;
            }
        }

        a {
            &.thumbnail-logo {
                img {
                    padding: 30px 48px;

                    @media #{$small-mobile} {
                        padding: 30px 15px;
                    }
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            margin-right: 55px;

            @media #{$laptop-device} {
                margin-right: 30px;
            }

            @media #{$smlg-device} {
                margin-right: 15px;
            }

            @media #{$small-mobile} {
                margin-right: 5px;
            }

            .rts-btn {
                @media #{$small-mobile} {
                    padding: 11px;
                    font-size: 14px;
                }
            }

            #menu-btn {
                @media #{$small-mobile} {
                    margin-left: 5px !important;
                }
            }
        }
    }
}



// header two start
.header-two {
    .header-top {
        background: #fff;
        align-items: center;
        position: relative;
        z-index: 1;
        overflow: hidden;
        border-bottom: 1px solid #EAECF2;

        @media #{$smlg-device} {
            display: none;
        }

        &::after {
            position: absolute;
            left: -5%;
            content: '';
            top: 0;
            width: 40%;
            height: 100%;
            background: var(--color-primary-2);
            z-index: -1;
            transform: skew(-20deg);
        }

        .content {
            max-width: 1560px;
            margin: 0 auto;
            display: flex;
            height: 45px;
            display: flex;
            align-items: center;
            width: 98%;

            .left-header-top {
                p {
                    font-size: 16px;
                    color: #fff;

                    a {
                        font-weight: 500;

                        i {
                            transition: .3s;
                        }

                        &:hover {
                            color: #000;

                            i {
                                margin-left: 6px;
                            }
                        }
                    }
                }
            }

            .right-header-top {
                margin-left: auto;
                display: flex;
                align-items: center;

                .working-time {
                    i {
                        color: #1C2539;
                    }

                    span {
                        color: #1C2539;
                        font-size: 16px;
                        font-weight: 400;
                        margin-left: 7px;
                    }
                }

                .ht-social {
                    display: flex;
                    align-items: center;
                    margin-left: 60px;
                    position: relative;

                    &::after {
                        position: absolute;
                        left: -17%;
                        content: '';
                        height: 100%;
                        width: 1px;
                        background: #CFD2DC;
                        transform: skew(-10deg);
                    }

                    span {
                        color: #1C2539;
                        font-weight: 400;
                    }

                    ul {
                        list-style: none;
                        padding: 0;
                        display: flex;
                        margin: 0;
                        margin-left: 20px;

                        li {
                            margin-right: 20px;

                            a {
                                i {
                                    color: #1C2539;
                                    transition: .3s;
                                }

                                &:hover {
                                    i {
                                        color: var(--color-primary-2);
                                    }
                                }
                            }

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .main-header {
        justify-content: flex-start;
        display: block;
        background: #fff;

        .content {
            max-width: 1560px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            width: 98%;

            @media #{$smlg-device} {
                max-width: 97%;
                margin: auto;
            }

            @media #{$small-mobile} {
                max-width: 99%;
            }

            .header-left {
                display: flex;
                align-items: center;

                a {
                    &.thumbnail {
                        margin-right: 20px;

                        @media #{$sm-layout} {
                            margin-right: 0;
                        }

                        img {
                            width: 100%;

                            @media #{$small-mobile} {
                                width: 73%;
                            }
                        }
                    }

                    @media #{$smlg-device} {
                        padding: 20px 0;
                    }

                    @media #{$sm-layout} {
                        padding: 20px 0 20px 15px;
                        width: 80%;
                        margin-right: 0;
                    }

                    @media #{$small-mobile} {
                        width: 90%;
                    }
                }
            }

            .header-right {
                margin-left: auto;
                display: flex;
                align-items: center;

                .call-area {
                    display: flex;
                    align-items: center;

                    @media #{$sm-layout} {
                        display: none;
                    }

                    .icon {
                        position: relative;
                        z-index: 1;
                        cursor: pointer;

                        &::after {
                            position: absolute;
                            content: '';
                            left: 50%;
                            top: 50%;
                            background: #F6F6F6;
                            height: 55px;
                            width: 55px;
                            z-index: -1;
                            border-radius: 10px;
                            transform: translate(-50%, -50%);
                            transition: .3s;
                        }

                        &:hover {
                            &::after {
                                background: #fff;
                                box-shadow: 0px 9px 18px rgba(24, 16, 16, 0.05);
                            }
                        }
                    }

                    .number-area {
                        margin-left: 30px;

                        span {
                            color: #5D666F;
                            font-size: 14px;
                        }

                        a {
                            .call {
                                margin-bottom: 0;
                                margin-top: 4px;
                                min-width: max-content;
                            }
                        }
                    }
                }

                a {
                    &.rts-btn {
                        margin-left: 40px;

                        @media #{$laptop-device} {
                            margin-left: 20px;
                        }

                        @media #{$large-mobile} {
                            margin-left: 0;
                            padding: 12px 15px;
                            font-size: 14px;
                        }
                    }
                }

                button {
                    max-width: fit-content;
                    background: #F7F7F7;
                    padding: 19px;

                    .menu-dark {
                        display: block;
                    }

                    .menu-light {
                        display: none;
                    }

                    &:hover {
                        background: var(--color-primary-2);

                        .menu-dark {
                            display: none;
                        }

                        .menu-light {
                            display: block;
                        }
                    }
                }
            }
        }

        nav {
            margin-left: 135px;

            @media #{$laptop-device} {
                margin-left: 10px;
            }
        }
    }
    &.eight{
        .header-right {
            margin-left: auto;
            display: flex;
            align-items: center;

            .call-area {
                display: flex;
                align-items: center;

                @media #{$sm-layout} {
                    display: none;
                }

                .icon {
                    position: relative;
                    z-index: 1;
                    cursor: pointer;

                    &::after {
                        position: absolute;
                        content: '';
                        left: 50%;
                        top: 50%;
                        background: #F6F6F6;
                        height: 55px;
                        width: 55px;
                        z-index: -1;
                        border-radius: 10px;
                        transform: translate(-50%, -50%);
                        transition: .3s;
                    }

                    &:hover {
                        &::after {
                            background: #fff;
                            box-shadow: 0px 9px 18px rgba(24, 16, 16, 0.05);
                        }
                    }
                }

                .number-area {
                    margin-left: 30px;

                    span {
                        color: #fff !important;
                        font-size: 14px;
                    }

                    a {
                        .call {
                            margin-bottom: 0;
                            margin-top: 4px;
                            min-width: max-content;
                            color: #fff;
                        }
                    }
                }
            }

            a {
                &.rts-btn {
                    margin-left: 40px;

                    @media #{$laptop-device} {
                        margin-left: 20px;
                    }

                    @media #{$large-mobile} {
                        margin-left: 0;
                        padding: 12px 15px;
                        font-size: 14px;
                    }
                }
            }

            button {
                max-width: fit-content;
                background: #F7F7F7;
                padding: 19px;

                .menu-dark {
                    display: block;
                }

                .menu-light {
                    display: none;
                }

                &:hover {
                    background: var(--color-primary-2);

                    .menu-dark {
                        display: none;
                    }

                    .menu-light {
                        display: block;
                    }
                }
            }
        }
    }
}


.home-blue {
    .main-header .nav-main ul li a:hover {
        color: var(--color-primary-2);
    }

    .main-header .nav-main ul li a::after {
        background: var(--color-primary-2);
    }

    .mainmenu li.has-droupdown .submenu {
        border-top-color: var(--color-primary-2);

        &::after {
            top: -20px;
            left: 16%;
            transform: translateX(-50%);
            position: absolute;
            content: "";
            border: 8px solid transparent;
            border-bottom-color: var(--color-primary-2);
        }
    }
    &.medium-blue{
        .main-header{
            background: #06277b;
        }
        .main-header .nav-main ul li a{
            color: #fff;
        }
        .main-header .nav-main ul li a:hover {
            color: var(--color-primary-2) !important;
        }
    
        .main-header .nav-main ul li a::after {
            background: var(--color-primary-2);
        }
    
        .mainmenu li.has-droupdown .submenu {
            border-top-color: var(--color-primary-2);
            
    
            &::after {
                top: -20px;
                left: 16%;
                transform: translateX(-50%);
                position: absolute;
                content: "";
                border: 8px solid transparent;
                border-bottom-color: var(--color-primary-2);
            }
        }
    }
}

.header-one {
    &.header--sticky {
        &.sticky {
            .header-top {
                display: none;
            }
        }
    }
}
.header-three {
    &.sticky {
        .header-top-three {
            display: none;
        }
    }
}

.home-blue {
    .header-two {
        &.header--sticky {
            &.sticky {
                .header-top {
                    display: none;
                }
            }
        }
    }
}

.search-input-area.show {
    visibility: visible;
    transform: inherit;
    opacity: 1;
}

.search-input-area {
    transition: all 500ms ease;
    visibility: hidden;
    transform: translateY(-100%);
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 57px 0;
    background: white;
    box-shadow: 1px 1px 50px rgb(0 0 0 / 46%);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

    @media #{$small-mobile} {
        padding: 20px 0;
    }

    .search-input-inner {
        display: flex;
        align-items: center;
        position: relative;

        .input-div {
            width: 80%;
            display: flex;
            align-items: center;
            margin: auto;

            input {
                background: #F7F7F7;
                border-radius: 5px;
                height: 55px;
            }

            button {
                max-width: max-content;
                padding: 17.5px;
                background: var(--color-primary);
                display: flex;
                color: #fff;
                align-items: center;
                justify-content: center;
                display: block;
                margin-left: -9px;
                border-radius: 0 5px 5px 0;
            }
        }
    }
    .search-close-icon {
        cursor: pointer;
        position: absolute;
        right: 18px;
        top: 12px;
        @media(max-width:500px){
            display: none;
        }

        i {
            position: relative;
            z-index: 1;
            color: var(--color-primary);
            transition: .3s;
            font-size: 18px;

            &::after {
                position: absolute;
                height: 45px;
                width: 45px;
                content: '';
                border-radius: 5px;
                background: var(--color-primary-alta);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
                transition: .3s;
            }
        }

        &:hover {
            i {
                color: var(--color-primary);

                &::after {
                    background: var(--color-primary-alta);
                }
            }
        }
    }
}

#anywhere-home {
    cursor: url(../images/banner/shape/close.png), auto;
    background: #0e1013;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 500ms ease-in-out;
    pointer-events: none;
    z-index: 50;

    &.bgshow {
        background: #0e1013;
        opacity: 70%;
        visibility: visible;
        pointer-events: visible;
        z-index: 1000;
        top: 0;
    }
}

li.has-droupdown {
    margin-right: 48px !important;
}

.menu-block-none {
    @media #{$large-mobile} {
        display: none;
    }
}


.header--sticky{
    &.header-one{
        &.six{
            position: absolute;
            width: 100%;
            .header-top{
                background: transparent;
                border-bottom: 1px solid #E8E8E8;
                padding: 12px 0;
                .right{
                    i{
                        color: var(--color-primary-5);
                        margin-right: 12px;
                    }
                    .call{
                        margin-left: 30px;
                    }
                    a{
                        color: #181A1C;
                        font-weight: 500;
                    }
                }
            }
            .header-main-one{
                #menu-btn{
                    border-radius: 50% !important;
                    background: #fff !important;
                    @media #{$smlg-device} {
                        background: #eee !important;
                    }
                    &:hover{
                        background: var(--color-primary-5) !important;
                    }
                }
                .rts-btn{
                    &.quote-btn{
                        background: #181A1C;
                        border-radius: 100px;
                        &:hover{
                            color: #181A1C;
                            background: #f6f6f6 !important;
                        }
                    }
                }
            }
        }
    }
}

.header--sticky.header-one.six .header-top{
    @media #{$smlg-device} {
        display: none;
    }
}

.header--sticky.header-one.six.sticky .header-main-one #menu-btn {
    background: #f6f6f6 !important;
}
.header--sticky.header-one.six.sticky .header-main-one #menu-btn:hover {
    background: var(--color-primary-5) !important;
}


.header-five.header-six.seven{
    .main-header.main-header-four.main-header-five.main-header-six .button-area .btn-primary-4 {
        background: var(--color-primary-6);
        &.six{
            &:hover{
                background: var(--color-gray);
            }
        }
    }
    &:hover{
        background: var(--color-gray);
        color: #000;
    }
}



.header-top-one.header-top-four.header-top-five.seven .top-left a{
    color: var(--color-primary-6);
}

.header-top-one.header-top-four.header-top-five.seven .right-h-three .header-top-right .email i{
    color: var(--color-primary-5);
}


.header--sticky{
    &.header-one{
        &.six{
            &.sticky{
                background: #fff !important;
            }
        }
    }
}




.header-top-one.header-top-four.header-top-five.seven .right-h-three .header-top-right .call i{
    color: var(--color-primary-6);
}   
.header-top-one.header-top-four.header-top-five.seven .right-h-three .header-top-right .email i{
    color: var(--color-primary-6);
}

.header-four.header-five.seven .nav-main ul li:hover a.nav-link::before{
    color: var(--color-primary-6);
}
.header-four.header-five.seven .nav-main ul li:hover a.nav-link::after{
    background: var(--color-primary-6);
}
.header-four.header-five.seven .nav-main ul li:hover a.nav-link{
    color: var(--color-primary-6);
}
.main-header.main-header-four.main-header-six.seven .nav-main ul li .submenu{
    border-color: var(--color-primary-6);
}
.main-header.main-header-four.main-header-six.seven .nav-main ul li .submenu::after{
    border-bottom-color: var(--color-primary-6);
}

.main-header.main-header-four.main-header-five.main-header-six.seven .button-area .btn-primary-alta-four:hover{
    background: var(--color-primary-6) !important;
}   

.header-top-one.header-top-four.seven .right-h-three .header-top-right .email a:hover{
    color: var(--color-primary-6) !important;
}

.header-top-one.header-top-four.seven .right-h-three .header-top-right .call a:hover{
    color: var(--color-primary-6);
}